.BookmarkItem {
  padding: 5px;
  margin-bottom: 10px;
  box-shadow: 0 0 1px 1px rgba(0,0,0,0.2);
}

.BookmarkItem__title {
  margin: 0 0 5px;
}

.BookmarkItem__title a {
  color: #263596;
}

.BookmarkItem__row {
  display: flex;
  justify-content: space-between;
}

.BookmarkItem__description {
  text-align: left;
  margin: 4px 0;
}

.AddBookmarkItem__required {
  color: #b00c0c;
}

.BookmarkItem__buttons {
  font-size: .8em;
  text-align: right;
  display: flex;
  justify-content: space-between;
}
