body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.AddBookmark__form {
  text-align: left;
}

.AddBookmark__form input,
.AddBookmark__form textarea {
  display: block;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 15px;
  /* minus side padding and border */
  width: calc(100% - 16px - 2px);
  padding: 5px 8px;
  border: 1px solid #ddd;
}

.AddBookmark__form label {
  margin-top: 10px;
}

.AddBookmark__buttons {
  text-align: center;
}

.AddBookmark__required {
  color: #a51414;
}

.AddBookmark__error p {
  color: #a51414;
  margin-bottom: 5px;
}

.rating {
  color: #d47500;
  padding: 0 1px;
}

.BookmarkItem {
  padding: 5px;
  margin-bottom: 10px;
  box-shadow: 0 0 1px 1px rgba(0,0,0,0.2);
}

.BookmarkItem__title {
  margin: 0 0 5px;
}

.BookmarkItem__title a {
  color: #263596;
}

.BookmarkItem__row {
  display: flex;
  justify-content: space-between;
}

.BookmarkItem__description {
  text-align: left;
  margin: 4px 0;
}

.AddBookmarkItem__required {
  color: #b00c0c;
}

.BookmarkItem__buttons {
  font-size: .8em;
  text-align: right;
  display: flex;
  justify-content: space-between;
}

.BookmarkList__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

label {
    display: block;
    text-align: left;
    padding-top: 20px;
    padding-bottom: 5px;
}

input {
    display: block;
    text-align: left;
    padding: 5px 8px;
    width: 40%;
}

button {
    float:left;
    margin: 10px;
}
.App {
  text-align: center;
  height: 100%;
  margin: 10px auto;
  padding: 5px;
  max-width: 560px;
  color: #444;
}

h1 {
  color: #666;
}

h2 {
  color: #333;
}

button {
  border: 1px solid #aaa;
  background-color: #eee;
  font-size: 1.1em;
  border-radius: 4px;
}

@media screen and (min-width: 460px) {
  body,
  input,
  textarea {
    font-size: 1.1em;
  }
}

