label {
    display: block;
    text-align: left;
    padding-top: 20px;
    padding-bottom: 5px;
}

input {
    display: block;
    text-align: left;
    padding: 5px 8px;
    width: 40%;
}

button {
    float:left;
    margin: 10px;
}